export const firebaseConfig = {
    "projectId": "guidde-dev-staging",
    "appId": "1:824103114815:web:920ecf835920e36ead808a",
    "databaseURL": "https://guidde-dev-staging-default-rtdb.firebaseio.com",
    "storageBucket": "guidde-dev-staging.appspot.com",
    "locationId": "us-central",
    "apiKey": "AIzaSyAECvQGtSbTa6P0o69l-fGgrMp2xLAeKlY",
    "authDomain": "stg.guidde.com",
    "messagingSenderId": "824103114815",
    "measurementId": "G-HMRHP70X5B",
    "environment": "staging"
}

export const ENABLE_SAOLA =
'true'
export const GDRIVE_PICKED_API_KEY =
'AIzaSyBoUnV2XavthiNEO_rvlAfLAbZ6FAy2apY'
export const MS_CLIENT_ID =
'920bb2a3-6a38-48f6-af4f-47849df20d69'
export const SAOLA_ACCOUNT_TOKEN =
'856f10ef-4168-4be6-8d73-06030e21a9a0'
export const STIGG_CLIENT_KEY =
'$2b$10$2tLiBrjgm05excoNtNqQS.PvzVTP.dnsM3fL/Jk77AeedV6moEpIO:0bd8f132-b309-46b2-b611-146a5c7a2d8f'
export const WEB_CLIENT_ID =
'824103114815-jbt3sku7g0nhhpndnbrh31f5g3qebun6.apps.googleusercontent.com'
export const entailApiKey =
'et-WJgibNJi14XN2kj8esMTq2ke_JhVpI8n5kq0lPv1-2eEnbIv'
export const isSeparateNetworkIssuesInMonitoring =
'true'
export const tapfiliateAccountId =
'47910-93439a'